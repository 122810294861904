<template>
  <BlankLayout>
    <div class="streamer-tipalti">
      <iframe
        width="100%"
        height="100%"
        v-if="src"
        :src="src"
      />
    </div>
  </BlankLayout>
</template>

<script>
import BlankLayout from 'components/layouts/BlankLayout.vue'
import CryptoJS from 'crypto-js'

export default {
  components: {
    BlankLayout,
  },
  data: () => ({
    src: '',
  }),
  computed: {
    user: ({ $store }) => $store.getters['auth/user'],
  },
  mounted () {
    const payeeId = this.user.user_id
    const time = Date.now() / 1000
    const masterKey = 'j0YPT6AkeKPUl3z8+glS5S0mt4wjU9G4EuglK0/q/X659Qih7ds/GCBseRmmCDbS'
    const UTF8Query = encodeURI(`idap=${payeeId}&payer=Uplify&ts=${time}`)
    const hmac = CryptoJS.HmacSHA256(UTF8Query, masterKey)
    const hex = CryptoJS.enc.Hex.stringify(hmac)
    const url = 'https://ui2.tipalti.com/payeedashboard/home'
    const currentDomain = window.location.origin
    const redirectDomain = `${currentDomain}/wallet`

    this.src = `${url}?${UTF8Query}&hashkey=${hex}&redirectto=${redirectDomain}`
  },
}
</script>

<style lang="scss" scoped>
.streamer-tipalti {
  height: 100vh;
}
</style>
